import React, { useLayoutEffect, useContext } from 'react';
import Layout from '../components/layout';
import { ThemeContext } from '../providers/theme';
import Products from '../components/Products';
import InfoCards from '../components/InfoCard';
import InfoContainer from '../components/InfoContainer';
import SEO from '../components/seo';
import { handleProducts } from '../utils/handleProducts';
import { graphql, useStaticQuery } from 'gatsby';
import Breadcrumbs from '../components/Breadcrumbs';

function DessertWine() {
  const { setTheme } = useContext(ThemeContext);

  useLayoutEffect(() => {
    setTheme('dessertWine');
  }, []);

  const data = handleProducts(
    useStaticQuery(graphql`
      {
        allSitePage(
          filter: {
            context: {
              productType: { eq: "dessertvin" }
              isProduct: { eq: "true" }
            }
          }
        ) {
          edges {
            node {
              path
              context {
                productTitle
                productPrice
                productOldprice
                productLink
                productImage
                productDescription
                productCategory
                productAvailable
                productType
              }
            }
          }
        }
      }
    `)
  );

  return (
    <React.Fragment>
      <SEO
        title={'Dessertvin'}
        description={
          'Find den helt rigtige dessertvin. Vi samler udvalg i samarbejde med en håndfuld af landets vinforhandlere, for at gøre det nemt for dig at finde den helt rette flaske'
        }
      />
      <Layout themeColor="#400000" title="Dessertvin">
        <Products type="dessert" data={data} />
        <InfoCards
          firstColumn={{
            title: 'Forskellige dessertvine til forskellige smagsløg',
            text:
              'Vi bestræber os på at kunne præsentere så mange forskellige slags dessertvine for dig som muligt, så du finder den helt rigtige. Når du har fundet den vin som passer dig, så bliver du sendt videre til forhandleren, hvor du kan købe vinen.'
          }}
          secondColumn={{
            title: 'Mange forskellige slags dessertvine',
            text:
              'Der findes et hav af forskellige slags dessertvine, som alle er gode på hver deres måde. Fælles for dessertvine er, at de er søde. De mest populære dessertvine er vine lavet på enten Moscato, Chenin blanc eller Gewürztraminer. '
          }}
          thirdColumn={{
            title: 'Dessertvine fra forskellige lande',
            text:
              'De forskellige dessertvine bliver produceret rundt omkring i mange forskellige lande, blandt andet i Italien, New Zealand, Tyskland og mange flere.'
          }}
        />
        <InfoContainer
          title={'Vidste du...'}
          text={
            'At dessertvine er kendte for deres sødme, også selvom man kalder den søde vin for dessertvin, så passer alle slags ikke til alle slags desserter. Nogle passer bedre til lette og søde desserter, hvor andre passer til ost eller frugt.  \n\nDessertvinen adskiller sig fra tørre vine på den måde, at der er ugæret restsukker i dem, og det er den store mængde sukker, som gør dem søde.  Når man starter arbejdet med at producere dessertvine, så bruger man en ekstremt sød og koncentreret druemost. Denne most er lavet af sukker og gær.  \n\nMan kan lave dessertvin på fire forskellige måder. Første måde kaldes ædel råddenskab, som er hvor svampen botrytis cinera angriber druerne, under morgentågen i de køligere klimaer. Svampen angriber drueskallen, og når solen står op og rammer, så stopper svampen med at vækste og vandet kan fordampe ud af druen.  \n\nMan kan også høste druerne, som er frosne, hvorefter man kan presse mosten ud af druerne, fordi vandet er i fast form.  \n\nMan kan også vælge at tørre druerne eller at stoppe gæringen ved at tilsætte spiritus og på den måde slå gæret ihjel. '
          }
        />
        <Breadcrumbs type={'dessertvin'} />
      </Layout>
    </React.Fragment>
  );
}

export default DessertWine;
